import {
  LineItemToAdd,
  AttributeInput,
  CustomAttribute,
  Cart,
} from 'shopify-buy'
import { NFTsByDiscountCode } from '../discounts/types'
import { getResponse, shopifyBuyClient } from './index'

export function createCheckout() {
  return shopifyBuyClient.checkout
    .create()
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: error, data: {} }
    })
}

export function fetchCheckout(checkoutId: string) {
  return shopifyBuyClient.checkout
    .fetch(checkoutId)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: error, data: {} }
    })
}

export function addLineItems(checkoutId: string, lineItems: LineItemToAdd[]) {
  if (!checkoutId || lineItems.length === 0) {
    return { success: false, message: 'Invalid item data', data: {} }
  }

  return shopifyBuyClient.checkout
    .addLineItems(checkoutId, lineItems)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: error, data: {} }
    })
}

export function removeLineItems(
  checkoutId: string,
  lineItemsToRemove: string[]
) {
  if (!checkoutId || lineItemsToRemove.length === 0) {
    return { success: false, message: 'Invalid item data', data: {} }
  }

  return shopifyBuyClient.checkout
    .removeLineItems(checkoutId, lineItemsToRemove)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: (error as Error).message, data: {} }
    })
}

export function updateLineItems(
  checkoutId: string,
  lineItemsToUpdate: AttributeInput[]
) {
  if (!checkoutId || lineItemsToUpdate.length === 0) {
    return { success: false, message: 'Invalid item data', data: {} }
  }

  return shopifyBuyClient.checkout
    .updateLineItems(checkoutId, lineItemsToUpdate)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: (error as Error).message, data: {} }
    })
}

export function addCustomAttributes(
  checkoutId: string,
  customAttributes: CustomAttribute[]
) {
  return shopifyBuyClient.checkout
    .updateAttributes(checkoutId, { customAttributes })
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: (error as Error).message, data: {} }
    })
}

export async function getCustomAttributes(
  discountsData: NFTsByDiscountCode,
  madLadEngraving?: string
) {
  const response = await fetch('/api/checkout/custom-attributes', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ discountsData, madLadEngraving }),
  })
  return response.json()
}

export function removeCustomAttributes(checkoutId: string) {
  return addCustomAttributes(checkoutId, [])
}

export function addDiscount(checkoutId: string, discountCode: string) {
  return shopifyBuyClient.checkout
    .addDiscount(checkoutId, discountCode)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: (error as Error).message, data: {} }
    })
}

export function removeDiscount(checkoutId: string) {
  return shopifyBuyClient.checkout
    .removeDiscount(checkoutId)
    .then((checkout: Cart) => {
      return getResponse<Cart>(checkout)
    })
    .catch((error) => {
      return { success: false, message: (error as Error).message, data: {} }
    })
}

export function addGiftCard(checkoutId: string, giftCardCode: string) {
  return (
    shopifyBuyClient.checkout
      // @ts-ignore - addGiftCards is not in the type definition
      .addGiftCards(checkoutId, [giftCardCode])
      .then((checkout: Cart) => {
        return getResponse<Cart>(checkout)
      })
      .catch((error: Error) => {
        return { success: false, message: (error as Error).message, data: {} }
      })
  )
}

export function removeGiftCard(checkoutId: string, giftCardId: string) {
  return (
    shopifyBuyClient.checkout
      // @ts-ignore - removeGiftCard is not in the type definition
      .removeGiftCard(checkoutId, giftCardId)
      .then((checkout: Cart) => {
        return getResponse<Cart>(checkout)
      })
      .catch((error: Error) => {
        return { success: false, message: error.message, data: {} }
      })
  )
}

export function allowPartialAddresses(checkoutId: string) {
  return (
    shopifyBuyClient.checkout
      // @ts-ignore - updateAttributes is not in the type definition
      .updateAttributes(checkoutId, { allowPartialAddresses: true })
      .then((checkout: Cart) => {
        return getResponse<Cart>(checkout)
      })
      .catch((error: Error) => {
        return { success: false, message: error.message, data: {} }
      })
  )
}

export interface ShippingAddress {
  country: string
  province?: string
  city?: string
}

export function updateShippingAddress(
  checkoutId: string,
  shippingAddress: ShippingAddress
) {
  return (
    shopifyBuyClient.checkout
      // @ts-ignore - updateShippingAddress is not in the type definition
      .updateShippingAddress(checkoutId, shippingAddress)
      .then((checkout: Cart) => {
        return getResponse<Cart>(checkout)
      })
      .catch((error: Error) => {
        return { success: false, message: error.message, data: {} }
      })
  )
}
