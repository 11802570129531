import CloseIcon from '@mui/icons-material/Close'
import { ReactElement } from 'react'

export const SidebarNav = (props: {
  onClose: () => void
  title?: string
  bodyContent: ReactElement
  isMobile?: boolean
}) => {
  return (
    <div className="mt-10 pl-5 pr-5 h-full relative flex flex-col">
      <div
        className={`flex ${props.isMobile ? 'justify-end' : 'justify-between'}`}
      >
        {props.title && <p className="text-2xl font-bold">{props.title}</p>}
        <div>
          <span
            onClick={() => props.onClose()}
            className="material-icons cursor-pointer"
          >
            <CloseIcon
              className={`${props.isMobile ? 'text-sm mb-4' : 'text-2xl'}`}
            />
          </span>
        </div>
      </div>
      {props.bodyContent}
    </div>
  )
}
