import styled from '@emotion/styled'

export const StyledBaseList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    color: #fff;
    &:hover {
      color: #ccc;
    }
  }
`

export const StyledList = styled(StyledBaseList)`
  li {
    margin-top: 0.5rem;
    @media (min-width: 768px) {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  @media (min-width: 768px) {
    margin-left: 2.5rem;
  }
  @media (min-width: 1024px) {
    margin-left: 7.5rem;
  }
`
