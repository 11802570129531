import Link from 'next/link'

import { Logo } from '../Logo'
import { targetBlank } from '../../utils/linkUtils'
import { StyledList } from './Common'
import GapDivider from '../BottomDivider/GapDivider'
import React from 'react'
import FooterBottom from './FooterBottom'

export const Footer = () => {
  return (
    <div className="md:mb-20">
      <GapDivider variant="long" />
      <footer className="flex flex-col md:flex-row justify-between py-6 md:py-[4.5rem]">
        <Logo alt="Footer Logo" />
        <div className="flex flex-col md:flex-row">
          <StyledList className="!mt-7 md:!mt-0">
            <li className="mb-4">
              <p className="font-medium text-lg text-[#8E8E93]">Saga</p>
            </li>
            <li>
              <Link href="https://solanamobile.com/hardware" {...targetBlank}>
                Saga
              </Link>
            </li>
            <li>
              <Link href="/">Store</Link>
            </li>
          </StyledList>

          <StyledList className="!mt-7 md:!mt-0">
            <li className="mb-4">
              <p className="font-medium text-lg text-[#8E8E93]">Developers</p>
            </li>
            <li>
              <Link href="https://solanamobile.com/developers" {...targetBlank}>
                Solana Mobile Stack
              </Link>
            </li>
            <li>
              <Link
                href="https://github.com/solana-mobile/solana-mobile-stack-sdk"
                {...targetBlank}
              >
                SDK
              </Link>
            </li>
            <li>
              <Link
                href="https://github.com/solana-mobile/dapp-publishing/blob/main/README.md"
                {...targetBlank}
              >
                Submit to the dApp Store
              </Link>
            </li>
          </StyledList>

          <StyledList className="!mt-7 md:!mt-0">
            <li className="mb-4">
              <p className="font-medium text-lg text-[#8E8E93]">Help</p>
            </li>
            <li>
              <Link href="https://solanamobile.com/support" {...targetBlank}>
                Support
              </Link>
            </li>
            <li>
              <Link href="https://returns.solanamobile.com/" {...targetBlank}>
                Returns
              </Link>
            </li>
            <li>
              <Link href="https://solanamobile.com/warranty" {...targetBlank}>
                Warranty
              </Link>
            </li>
          </StyledList>
        </div>
      </footer>
      <GapDivider variant="long" />
      <FooterBottom />
    </div>
  )
}
