import Link from 'next/link'
import Image from 'next/image'

export const Logo = ({ alt = 'logo', className = '' }) => (
  <div className={className}>
    <Link
      href={`/`}
      className="block relative min-h-[16px] md:min-h-[24px] w-[140px] md:w-[210px] cursor-pointer"
    >
      <Image
        src={'/Logo.svg'}
        fill
        alt={alt}
        style={{
          objectFit: 'contain',
        }}
      />
    </Link>
  </div>
)
