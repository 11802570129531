import React, { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import { Product, Variant } from 'shopify-buy'

import { ProductImageDetail } from '../Product'
import { USDollar } from '../../lib/utils'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import {
  compareGID,
  filterCableFromAccessories,
  parseVariantId,
} from '../../lib/shopify'
import { isPhoneProduct } from '../../providers/utils'
import { SAGA_PHONE_PRODUCT_IDS } from '../../utils/constants'

const accessorySorter =
  (isAdded: (variant: Variant) => ShopifyBuy.LineItem | undefined) =>
  (productA: Product, productB: Product): number => {
    const variantA = productA.variants[0]
    const variantB = productB.variants[0]
    const idCompare =
      parseInt(`${parseVariantId(variantB.id)}`) -
      parseInt(`${parseVariantId(variantA.id)}`)
    const addedCompare = Number(!isAdded(variantB)) - Number(!isAdded(variantA))
    if (
      isPhoneProduct(productA.id.toString()) ||
      isPhoneProduct(productB.id.toString())
    ) {
      return isPhoneProduct(productA.id.toString()) ? -1 : 1
    }
    return addedCompare === 0 ? idCompare : addedCompare
  }

export const ProductAccessories = () => {
  const [products, setProducts] = useState<Product[]>([])
  const {
    checkout,
    accessories,
    phoneCount,
    productVariant,
    updating,
    handleLineItemsToAdd,
  } = useCheckoutCart()

  useEffect(() => {
    if (accessories && accessories.products) {
      const accessoriesProducts = filterCableFromAccessories(
        accessories.products
      )
      if (phoneCount === 0 && productVariant?.product) {
        setProducts([productVariant.product, ...accessoriesProducts])
      } else {
        setProducts(accessoriesProducts)
      }
    }
  }, [accessories, phoneCount])

  const hasAccessoryBeenAdded = useCallback(
    (variant: Variant) => {
      return (
        checkout &&
        checkout.lineItems &&
        checkout.lineItems.find((lineItem) => {
          return compareGID(lineItem.variant.id, variant.id)
        })
      )
    },
    [checkout && checkout.lineItems]
  )

  const handleAddAccessory = async (accessory: Product) => {
    // We assume only one variant per product
    const variantToAdd: Variant = accessory.variants[0]
    variantToAdd.product = { ...accessory, variants: [] }
    if (!updating) {
      if (hasAccessoryBeenAdded(variantToAdd)) return
      await handleLineItemsToAdd(variantToAdd, 1)
    }
  }

  const sortProducts = accessorySorter(hasAccessoryBeenAdded)

  return (
    <div>
      <div className="text-2xl mb-4 font-semibold">
        Don&apos;t forget to add these:
      </div>
      <div className={`grid grid-cols-2 gap-4 ${updating && `grayscale`}`}>
        {products
          .filter(({ availableForSale }) => !!availableForSale)
          .sort(sortProducts)
          .map((product) => {
            const isAdded = hasAccessoryBeenAdded(product.variants[0])
            // const isSaga = SAGA_PHONE_PRODUCT_IDS.includes(product.id as string)
            // const oldPrice =
            //   isSaga && parseInt(`${product.variants[0].priceV2.amount}`) < 1000
            //     ? USDollar.format(parseFloat(`1000.00`))
            //     : ''
            return (
              <div
                key={`line-item-${product.id}`}
                className={`flex flex-col ${
                  isAdded
                    ? 'cursor-not-allowed opacity-30'
                    : updating
                    ? 'cursor-progress opacity-30'
                    : 'cursor-pointer'
                }`}
                onClick={() => handleAddAccessory(product)}
              >
                <ProductImageDetail
                  variant={product.variants[0]}
                  minHeight={'sm'}
                  showText={false}
                  roundedSize={`rounded-md ${
                    isAdded && `grayscale opacity-50`
                  }`}
                />
                <span
                  className={`font-semibold my-1 ${isAdded && `text-gray-400`}`}
                >
                  {product.title}
                </span>
                <div className="flex flex-row items-center justify-start gap-2">
                  <Image
                    src={isAdded ? '/circleCheckGray.svg' : '/add_circle.svg'}
                    width={24}
                    height={24}
                    alt={'add-accessory'}
                  />
                  {isAdded ? (
                    <span className="text-gray-400">Added</span>
                  ) : (
                    <>
                      {/*{oldPrice && (*/}
                      {/*  <span className="line-through">{oldPrice} </span>*/}
                      {/*)}*/}
                      <span>
                        {USDollar.format(
                          parseFloat(product.variants[0].priceV2.amount)
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
