import Image from 'next/image'

export const MenuIconSwitch = ({ connected = false }) => (
  <>
    {connected ? (
      <Image
        src={'/burgerMenuConnected.svg'}
        alt="Burger menu connected"
        width={24}
        height={24}
      />
    ) : (
      <Image src={'/burgerMenu.svg'} alt="Burger menu" width={24} height={24} />
    )}
  </>
)
