/**
 * Simple Twitter Conversion Provider for easy access to the twq() function.
 */
import React, { createContext, useContext, useMemo } from 'react'
import { TWITTER_PURCHASE_EVENT } from '../utils/constants'
import {
  createUUID,
  OrderItems,
  SAGA_IDS,
  SAGA_TITLES,
} from '../utils/conversionOrderItemUtils'

const TwitterConversionContext: React.Context<any> = createContext(null)

interface ITwitterConversionProps {
  children?: React.ReactNode
}

export enum EVENT_TYPE {
  PURCHASE = 'PURCHASE',
}

export enum EVENT_CONTENT_ID {
  PURCHASE = 'saga_purchase',
}

// export const DEFAULT_CURRENCY_CODE = 'USD'

export const TwitterConversionProvider: React.FC<ITwitterConversionProps> = ({
  children,
}) => {
  // @ts-ignore
  const hasTwq = typeof window !== 'undefined' && !!window.twq
  const twqFunction = useMemo(
    () =>
      hasTwq
        ? // @ts-ignore
          window.twq
        : () => {
            console.log('No TWQ found.')
          },
    [hasTwq]
  )
  return (
    <TwitterConversionContext.Provider value={twqFunction}>
      {children}
    </TwitterConversionContext.Provider>
  )
}

const sentConversionEventIds = new Set<string>()

const processCheckoutOrder = (
  orderItems: OrderItems,
  contentId: EVENT_CONTENT_ID
) => {
  try {
    let orderlineItems = orderItems.data.lineItems
    let lineItems = []

    for (const checkoutLineItem of orderlineItems.edges) {
      if (SAGA_TITLES.includes(checkoutLineItem.node.title)) {
        lineItems.push({
          content_type: contentId,
          content_id: SAGA_IDS[checkoutLineItem.node.title],
          content_name: checkoutLineItem.node.title,
          content_price: checkoutLineItem.node.variant.price,
          num_items: checkoutLineItem.node.quantity,
        })
      }
    }

    const payload = {
      currency: orderItems.data.currencyCode,
      value: orderItems.data.totalPriceSet.shopMoney.amount,
      contents: lineItems,
    }

    return payload
  } catch (err) {
    console.log(err)
    return null
  }
}

export const createTwitterConversionEvent = (
  twq: any,
  eventType: keyof typeof EVENT_TYPE
) => {
  if (!TWITTER_PURCHASE_EVENT || eventType !== EVENT_TYPE.PURCHASE) {
    return () => {}
  }
  const eventId = TWITTER_PURCHASE_EVENT
  // const eventName = EVENT_TYPE[eventType]
  const contentId = EVENT_CONTENT_ID[eventType]
  const generatedConversionId = createUUID()

  return ({
    conversionId,
    orderItems,
  }: {
    conversionId?: string
    orderItems: OrderItems
  }) => {
    const currentConversionId = conversionId
      ? conversionId
      : generatedConversionId

    if (
      sentConversionEventIds.has(currentConversionId) ||
      !orderItems.success
    ) {
      return
    }

    const checkoutOrderData = processCheckoutOrder(orderItems, contentId)

    twq('event', eventId, {
      ...checkoutOrderData,
      conversion_id: currentConversionId,
    })

    sentConversionEventIds.add(currentConversionId)
  }
}

export const useTwitterConversionEvent = (
  eventType: keyof typeof EVENT_TYPE
) => {
  const twq = useContext(TwitterConversionContext)
  const sendTwitterConversionEvent = useMemo(
    () => createTwitterConversionEvent(twq, eventType),
    [twq, eventType]
  )
  return { sendTwitterConversionEvent, twq }
}

/**
 * Example code:
 *
 *   const { sendTwitterConversionEvent } = useTwitterConversionEvent(
 *     EVENT_TYPE.PURCHASE
 *   );
 *   ...
 *   sendTwitterConversionEvent({
 *     conversionId: orderNumber,
 *     orderItems,
 *   });
 */
