import { useWallet } from '@solana/wallet-adapter-react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Drawer } from '@mui/material'

import { useState } from 'react'
import { ConnectButton } from '../ConnectButton'
import { SidebarNav } from './SidebarNav'
import { MenuIconSwitch } from './MenuIconSwitch'

export const MobileNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { connected } = useWallet()

  const handleClose = () => setMenuOpen(false)
  const handleOpen = () => setMenuOpen(true)
  return (
    <>
      <div onClick={handleOpen}>
        <MenuIconSwitch connected={connected} />
      </div>
      <Drawer
        PaperProps={{
          elevation: 8,
          sx: {
            width: '70%',
            height: '100%',
            backgroundColor: 'black',
            color: 'white',
          },
        }}
        className="shadow-md"
        variant={'persistent'}
        anchor="right"
        open={menuOpen}
        onClose={handleClose}
      >
        <SidebarNav
          bodyContent={
            <div className="flex flex-col navbar">
              <List>
                <ListItem disablePadding>
                  <ConnectButton onClick={handleClose} isMobile={true} />
                </ListItem>
              </List>
            </div>
          }
          onClose={handleClose}
          isMobile={true}
        />
      </Drawer>
    </>
  )
}
