var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a084bc2a66138a888305c1e9dc8c45f56e50b806"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { SENTRY_DSN } from './utils/constants'

import {
  Breadcrumbs,
  BrowserClient,
  Dedupe,
  FunctionToString,
  getCurrentHub,
  GlobalHandlers,
  HttpContext,
  InboundFilters,
  makeFetchTransport,
  TryCatch,
  defaultStackParser,
} from '@sentry/nextjs'

getCurrentHub().bindClient(
  new BrowserClient({
    stackParser: defaultStackParser,
    dsn: SENTRY_DSN,
    transport: makeFetchTransport,
    integrations: [
      new Breadcrumbs(), // ~7kB unminified
      new Dedupe(), // ~5kB unminified
      new FunctionToString(), // ~1kB unminified
      new GlobalHandlers(), // ~7kB unminified
      new HttpContext(), // ~2kB unminified
      new InboundFilters(), // ~6kB unminified
      new TryCatch(), // ~9kB unminified
    ],
  })
)
