import { CollectionWithProducts } from 'shopify-buy'
import { getResponse, shopifyBuyClient } from './index'

export const getAccessoriesCollection = async () => {
  return await getCollection(process.env.NEXT_PUBLIC_ACCESSORIES_COLLECTION_ID)
}

export const getSagaCollection = async () => {
  return await getCollection(process.env.NEXT_PUBLIC_SAGA_COLLECTION_ID)
}

const getCollection = async (collectionId: string | undefined) => {
  const gid = 'gid://shopify/Collection/'
  try {
    const collection: CollectionWithProducts =
      await shopifyBuyClient.collection.fetchWithProducts(
        `${gid}${collectionId}`
      )
    return getResponse<CollectionWithProducts>(collection)
  } catch (error) {
    const { captureException } = await import('@sentry/nextjs')
    captureException(error)
    const anyError = error as any
    const message = anyError.length > 0 ? anyError[0].message : anyError.message
    return {
      success: false,
      message: message,
      data: {} as CollectionWithProducts,
    }
  }
}
