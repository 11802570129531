import { CircularProgress, Divider } from '@mui/material'
import { OrderButton } from './OrderButton'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import { isEmptyObject, USDollar } from '../../lib/utils/lib'
import {
  addCustomAttributes,
  addDiscount,
  addGiftCard,
  getCustomAttributes,
  removeCustomAttributes,
  removeDiscount,
  removeGiftCard,
} from '../../lib/shopify/checkout'
import { useEffect, useState } from 'react'
import { NFTsByDiscountCode } from '../../lib/discounts/types'
import { Cart } from 'shopify-buy'
import { useWallet } from '@solana/wallet-adapter-react'
import { sagaCountInCheckout } from '../../lib/shopify'
import { FINISH_CHECKOUT, START_CHECKOUT } from '../../utils/constants'
import { captureException } from '@sentry/nextjs'
import { useInfoBox } from '../../context/useInfoBox'
import Link from 'next/link'
import { targetBlank } from '../../utils/linkUtils'
import { DomainError, getErrorMessage } from '../../lib/errors'
import { getUnusedSagaPasses } from '../../lib/nft/hasSagaPasses'
import { useVerifyWallet } from '../../context/useVerifyWallet'
import { GreenButton } from '../GreenButton'

interface CheckoutButtonsProps {
  setCheckoutUrl: (checkoutUrl: string) => void
  setCheckoutState: (checkoutState: number) => void
  isCartEmpty: boolean
}

export const CheckoutButtons = (props: CheckoutButtonsProps) => {
  const { enqueueInfo } = useInfoBox()
  const { checkout, loadCheckout } = useCheckoutCart()
  const [applyingDiscounts, setApplyingDiscounts] = useState(false)
  const { publicKey } = useWallet()
  const { verified, isVerifiedWithModal, setIsVerifyModalOpen } =
    useVerifyWallet()

  useEffect(() => {
    if (isVerifiedWithModal) {
      handlePayment()
    }
  }, [isVerifiedWithModal])

  const handlePayment = async () => {
    try {
      if (publicKey && !verified) {
        setIsVerifyModalOpen(true)
        return
      }
      setApplyingDiscounts(true)

      if (!publicKey) {
        await cleanUpCheckout(checkout)
        location.assign(checkout.webUrl)
        return
      }

      const sagaCount = sagaCountInCheckout(checkout.lineItems)
      const hasUnusedSagaPasses =
        (await getUnusedSagaPasses(publicKey)).length > 0

      if (hasUnusedSagaPasses) {
        props.setCheckoutState(START_CHECKOUT)
      }

      await cleanUpCheckout(checkout)
      const response = await fetch('/api/discounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sagaCount,
          checkout,
          publicKey: publicKey.toBase58(),
        }),
      })
      const jsonData = await response.json()

      if (!jsonData.success) {
        throw new Error(jsonData.message)
      }
      const data: NFTsByDiscountCode = jsonData.data

      const checkoutId = checkout.id.toString()
      const customAttributesResponse = await getCustomAttributes(data)
      if (!customAttributesResponse.success) {
        throw new DomainError(
          "We're having trouble applying your Saga Pass to your order. This should be resolved within a few minutes, but you'll need to try to checkout again."
        )
      }

      const addAttributesResponse = await addCustomAttributes(
        checkoutId,
        customAttributesResponse.data
      )

      if (!addAttributesResponse.success) {
        throw new DomainError(
          "We're having trouble applying your Saga Pass to your order. This should be resolved within a few minutes, but you'll need to try to checkout again."
        )
      }
      await Promise.all(
        Object.keys(data).map(async (discountCodeKey) => {
          const discountData = data[discountCodeKey]
          if (discountData.type === 'GiftCard') {
            await addGiftCard(checkoutId, discountCodeKey)
          } else {
            await addDiscount(checkoutId, discountCodeKey)
          }
        })
      )

      if (hasUnusedSagaPasses) {
        props.setCheckoutState(FINISH_CHECKOUT)
        props.setCheckoutUrl(checkout.webUrl)
      } else {
        location.assign(checkout.webUrl)
      }
    } catch (error) {
      const message = getErrorMessage(
        error,
        "Checkout isn't working properly. Refresh the page and try again."
      )
      enqueueInfo(message, { variant: 'error' })
      await cleanUpCheckout(checkout)
      setApplyingDiscounts(false)
      props.setCheckoutState(0)
      captureException(error)
    }
  }

  const cleanUpCheckout = async (checkout: Cart) => {
    try {
      await Promise.all(
        checkout.appliedGiftCards.map(async (giftCard) => {
          const giftCardId = giftCard.id.toString()
          await removeGiftCard(checkout.id.toString(), giftCardId)
        })
      )

      await removeDiscount(checkout.id.toString())
      await removeCustomAttributes(checkout.id.toString())
      await loadCheckout(true)
    } catch (error) {
      const message = getErrorMessage(
        error,
        "Checkout isn't working properly. Refresh the page and try again."
      )
      enqueueInfo(message, { variant: 'error' })
      captureException(error)
    }
  }

  return (
    <div className="relative h-auto flex flex-col w-full self-end mb-7">
      <Divider />
      <div className="flex mt-3 justify-between">
        <p>Estimated total</p>
        {
          <p className="ml-4 font-bold">
            {!isEmptyObject(checkout) &&
              USDollar.format(
                parseFloat(checkout.lineItemsSubtotalPrice.amount)
              )}
          </p>
        }
      </div>
      <p className="text-sm text-[#48484A] mt-3">
        Discounts, taxes and shipping calculated at checkout.
        <br /> One discount and one Saga Pass per order.
      </p>
      <div className="flex flex-col">
        {publicKey && !verified && !props.isCartEmpty ? (
          <GreenButton
            fullWidth
            extraClass="px-5 mt-5"
            onClick={() => setIsVerifyModalOpen(true)}
          >
            Verify wallet to proceed
          </GreenButton>
        ) : (
          <OrderButton
            background="bg-black"
            id="card"
            color="text-white"
            content={
              applyingDiscounts ? (
                <div>
                  Preparing your checkout
                  <CircularProgress
                    size={16}
                    className="mx-[13px] text-gray-400"
                  />
                </div>
              ) : (
                'Checkout'
              )
            }
            disabled={props.isCartEmpty}
            onClick={handlePayment}
          />
        )}
        <div className="self-center my-7 cursor-pointer text-sm text-center lg:text-left">
          By clicking &ldquo;Checkout&ldquo;, you agree to Solana Mobile’s{` `}
          <Link
            href="https://solanamobile.com/privacy-policy-web"
            {...targetBlank}
            className="font-bold underline"
          >
            Privacy Policy
          </Link>
          {` `}and{` `}
          <Link
            href="https://solanamobile.com/tos-web"
            {...targetBlank}
            className="font-bold underline"
          >
            Terms of Service
          </Link>
          {` `}
          including the arbitration clause linked{` `}
          <Link
            href="https://solanamobile.com/tos-web#disputes"
            {...targetBlank}
            className="font-bold underline"
          >
            here
          </Link>
          ,{` `}meaning that any dispute related to your Saga device will be
          resolved through BINDING ARBITRATION on an individual, non-class
          basis.
        </div>
      </div>
    </div>
  )
}
