import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { StyledBaseList } from './Common'
import { targetBlank } from '../../utils/linkUtils'

const SolanaMobileCopyright = () => {
  const year = new Date().getFullYear()
  return (
    <span className="text-[#8E8E93] col-12 col-md-7">
      &copy;&nbsp;{year}&nbsp; Solana Mobile, Inc.
    </span>
  )
}

const FooterBottom = () => {
  return (
    <div className="mt-7 flex flex-col-reverse md:grid md:grid-cols-5">
      <SolanaMobileCopyright />
      <div className="col-span-2" />
      <div className="col-span-2">
        <StyledBaseList className="flex flex-col md:flex-row justify-between">
          <li className="mb-2 md:mb-0">
            <Link href="https://solanamobile.com/tos-web" {...targetBlank}>
              Terms of Service
            </Link>
          </li>
          <li className="mb-2 md:mb-0">
            <Link
              href="https://solanamobile.com/privacy-policy-homepage-web"
              {...targetBlank}
            >
              Privacy Policy
            </Link>
          </li>
          <li className="mb-2 md:mb-0">
            <Link
              href="https://solanamobile.com/cookie-policy-web"
              {...targetBlank}
            >
              Cookie Policy
            </Link>
          </li>
          <StyledBaseList className="flex flex-col md:flex-row justify-between">
            <li className="mr-5 mb-2 md:mb-0">
              <Link href="https://discord.gg/solanamobile" {...targetBlank}>
                <Image
                  src={'/discord.svg'}
                  alt="Discord Logo"
                  width={24}
                  height={24}
                />
              </Link>
            </li>
            <li className="mb-5 md:mb-0">
              <Link href="https://twitter.com/solanamobile" {...targetBlank}>
                <Image
                  src={'/twitter.svg'}
                  alt="Twitter Logo"
                  width={24}
                  height={24}
                />
              </Link>
            </li>
          </StyledBaseList>
        </StyledBaseList>
      </div>
    </div>
  )
}

export default FooterBottom
