import { Cart } from '../Cart'
import { ConnectButton } from '../ConnectButton'
import { Logo } from '../Logo'
import { MobileNavbar } from '../Navbar/MobileNavbar'
import {
  DesktopMediaQuery,
  TabletOrMobileMediaQuery,
} from '../ResponsiveWrappers'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import ArrowBack from '@mui/icons-material/ArrowBack'

export const Header = () => {
  const router = useRouter()
  const notSupported = router.pathname === '/not-supported'

  const solanaPayHeader = router.pathname == '/post-order/[orderId]'
  return (
    <div
      className={`flex flex-row border-b-[1px] justify-between border-white/10 h-[56px] md:h-[70px] ${
        solanaPayHeader && 'items-center flex-col !justify-center !border-0'
      }`}
    >
      {solanaPayHeader ? (
        <>
          <div className="w-full flex flex-row justify-center md:w-1/2 items-center relative">
            <Link href={'/'} className={'absolute left-0'}>
              <ArrowBack />
            </Link>
            <Link href={'/'}>
              <Image src={'/payLogo.svg'} alt="logo" width={67} height={24} />
            </Link>
          </div>
        </>
      ) : (
        <>
          <Logo className="flex flex-row items-center" />

          {!notSupported && (
            <div className="flex flex-row justify-between items-center">
              <DesktopMediaQuery>
                <Cart />
                <ConnectButton />
              </DesktopMediaQuery>
              <TabletOrMobileMediaQuery>
                <Cart />
                <MobileNavbar />
              </TabletOrMobileMediaQuery>
            </div>
          )}
        </>
      )}
    </div>
  )
}
