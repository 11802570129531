import Image from 'next/image'
import { SwipeableHandlers } from 'react-swipeable'

interface ImageContainerProps {
  src: string
  roundedClass?: string
  priority?: boolean
  handleSwipe?: SwipeableHandlers
}

export const ImageContainer = ({
  src,
  roundedClass = 'rounded-lg',
  priority = false,
  handleSwipe,
}: ImageContainerProps) => {
  return (
    <div
      {...handleSwipe}
      className={`bg-[#1C1C1E] w-full h-full relative overflow-hidden ${roundedClass}`}
    >
      <Image
        src={src}
        fill
        alt="product"
        style={{
          objectFit: 'cover',
        }}
        priority={priority}
        {...(priority && {
          quality: 100,
          sizes: '100vw',
        })}
      />
    </div>
  )
}
