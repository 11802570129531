import React from 'react'
import styled from '@emotion/styled'

interface IGapDividerProps {
  variant: 'short' | 'long'
}

type DividerPartVariantProps = {
  $variant: 'short' | 'long'
}

export const StyledDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.5rem;
`

const StyledTealPart = styled.div<DividerPartVariantProps>`
  width: ${(props) => (props.$variant === 'short' ? `1.25` : `4.5625`)}rem;
  border-top: 1px solid #19fb9b;
`

const StyledNeutralPart = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
`

/**
 * Saga Mobile Divider with a Gap in two colors.
 *
 * @param {string}    variant    Display the teal part either "long" or "short".
 */
const GapDivider = ({ variant = 'short' }: IGapDividerProps) => {
  return (
    <StyledDividerWrapper>
      <StyledTealPart $variant={variant} />
      <StyledNeutralPart />
    </StyledDividerWrapper>
  )
}

export default GapDivider
