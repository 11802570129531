import memoizeOne from 'memoize-one'
import {
  APP_URL,
  RPC_ENDPOINT,
  SAGA_PASS_COLLECTION_ADDRESS,
  SAGA_PASS_STATUS_ATTR,
  SAGA_PASS_STATUS_UNUSED,
} from '../../utils/constants'

export interface GetAllNftsInput {
  walletAddress?: any
  mintList?: any[]
  metaplex: any
}

const rawGetAllNfts = async ({
  mintList,
  walletAddress,
  metaplex,
}: GetAllNftsInput) => {
  let allNFTs: any[] = []
  if (walletAddress) {
    allNFTs = await metaplex.nfts().findAllByOwner({ owner: walletAddress })
  } else if (mintList) {
    allNFTs = await metaplex.nfts().findAllByMintList({ mints: mintList })
  }

  return allNFTs
    .filter((nft) => nft !== null)
    .sort((a, b) => a.address.toString().localeCompare(b.address.toString()))
}

export const getAllNfts = memoizeOne(rawGetAllNfts)

export const getSagaPasses = async (
  walletAddress: any,
  loadMetadata = false
) => {
  const { Metaplex } = await import('@metaplex-foundation/js')
  const { Connection } = await import('@solana/web3.js')

  const connection = new Connection(RPC_ENDPOINT, {
    commitment: 'confirmed',
    httpHeaders: {
      Referer: APP_URL,
    },
  })

  const metaplex = Metaplex.make(connection)
  const allNFTs = await getAllNfts({ walletAddress, metaplex })
  let sagaPasses = allNFTs.filter((nft) => {
    return (
      nft.collection &&
      nft.collection.verified &&
      nft.collection.address.toString() === SAGA_PASS_COLLECTION_ADDRESS
    )
  })

  if (loadMetadata) {
    sagaPasses = await Promise.all(
      sagaPasses.map((nft) => {
        return metaplex.nfts().load({ metadata: nft as any })
      })
    )
  }

  return sagaPasses
}
//@ts-ignore
export const getUnusedSagaPasses = async (walletAddress: any) => {
  const allNFTs = await getSagaPasses(walletAddress, true)

  return allNFTs
    .filter(
      (nft) =>
        nft.jsonLoaded &&
        nft.json?.attributes?.some(
          //@ts-ignore
          ({ trait_type, value }) =>
            trait_type === SAGA_PASS_STATUS_ATTR &&
            value === SAGA_PASS_STATUS_UNUSED
        )
    )
    .map((nft) => nft.mint.address)
}

export const hasSagaPasses = async (walletAddress: any) =>
  (await getSagaPasses(walletAddress)).length > 0
