import { SolanaMobileWalletAdapterWalletName } from '@solana-mobile/wallet-adapter-mobile'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import Image from 'next/legacy/image'
import React, {
  PropsWithChildren,
  useEffect,
  RefObject,
  useRef,
  useState,
} from 'react'
import { shortenAddress } from '../../utils/strings'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { isMobileBrowser } from '../../lib/utils'
import {
  useVerifyWallet,
  VerifiedStatuses,
} from '../../context/useVerifyWallet'
import {
  WalletConnectionError,
  WalletNotReadyError,
} from '@solana/wallet-adapter-base'
import { useInfoBox } from '../../context/useInfoBox'

interface PropperOverlayProps {
  isOptionsOpen: boolean
  currentAnchor: HTMLDivElement | null
  isMobile?: boolean
  setIsOptionsOpen: (isOpen: boolean) => void
  setVisible: (isVisible: boolean) => void
  handleDisconnect: () => void
}

interface BaseButtonProps {
  onClick?: () => void
  connected?: boolean
  colorClass?: string
  icon?: string
  ref?: RefObject<HTMLDivElement>
  isMobile?: boolean
}

interface ConnectButtonProps extends BaseButtonProps {
  onConnect?: () => void
  onDisconnect?: () => void
  toBuy?: boolean
  showAsLink?: string | boolean
  onHomeScreen?: boolean
}

export const ConnectButton = (props: ConnectButtonProps) => {
  const {
    publicKey,
    connect,
    connected,
    disconnect,
    wallet,
    connecting,
    select: selectAdapter,
  } = useWallet()
  const { setVisible } = useWalletModal()
  const { enqueueInfo } = useInfoBox()
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const adapterRef = useRef(wallet?.adapter?.name)
  const { setVerifiedStatus } = useVerifyWallet()

  const handleConnect = async () => {
    try {
      wallet?.adapter?.name === SolanaMobileWalletAdapterWalletName ||
      !!wallet?.adapter
        ? await connect()
        : setVisible(true)
    } catch (error) {
      handleConnectErr(error as Error)
    }
  }

  const handleConnectErr = async (error: Error) => {
    if (
      error instanceof WalletNotReadyError ||
      error instanceof WalletConnectionError
    ) {
      if (!(error as Error).message) {
        selectAdapter(null)
      }
    } else {
      enqueueInfo(
        "Your wallet wasn't connected. Please try connecting it again.",
        { variant: 'error' }
      )
      const { captureException } = await import('@sentry/nextjs')
      captureException(error)
    }
  }

  const handleDisconnect = () => {
    disconnect()
    props.onDisconnect && props.onDisconnect()
    setVerifiedStatus(VerifiedStatuses.Init)
  }

  useEffect(() => {
    if (!wallet || connecting) return
    if (
      !connected &&
      wallet?.adapter?.name !== SolanaMobileWalletAdapterWalletName &&
      (!isMobileBrowser() || adapterRef.current !== wallet?.adapter?.name)
    ) {
      try {
        connect().catch((error) => {
          handleConnectErr(error as Error)
        })
      } catch (error) {
        handleConnectErr(error as Error)
      }
      return
    }
  }, [wallet?.adapter, connected, connecting, isMobileBrowser])

  if (props.showAsLink) {
    return !connected ? (
      <button
        onClick={() => {
          if (props.onClick) props.onClick()
          handleConnect()
        }}
        className={`text-[#14F195] w-full ${
          props.onHomeScreen && `text-center lg:text-start`
        }
          ${props.showAsLink == true && 'lg:text-start'}
          ${props.showAsLink == 'Connect wallet' && 'text-start'}`}
      >
        {props.showAsLink !== true
          ? props.showAsLink
          : 'Add your Saga Pass or NFT discount'}
      </button>
    ) : (
      <></>
    )
  }

  return !connected ? (
    <BaseButton
      connected={connected}
      onClick={() => {
        if (props.onClick) props.onClick()
        handleConnect()
      }}
      colorClass={props.colorClass}
      icon={props.icon}
      isMobile={props.isMobile}
    >
      Connect wallet{props.toBuy ? ' to buy' : ''}
    </BaseButton>
  ) : (
    <div className="relative" ref={anchorRef}>
      <BaseButton
        connected={connected}
        onClick={() => setIsOptionsOpen(true)}
        isMobile={props.isMobile}
      >
        {shortenAddress(publicKey!.toBase58())}
      </BaseButton>
      <PopperOverlay
        isOptionsOpen={isOptionsOpen}
        currentAnchor={anchorRef.current}
        isMobile={props.isMobile}
        setIsOptionsOpen={setIsOptionsOpen}
        setVisible={setVisible}
        handleDisconnect={handleDisconnect}
      />
    </div>
  )
}

const PopperOverlay = ({
  isOptionsOpen,
  currentAnchor,
  isMobile,
  setIsOptionsOpen,
  setVisible,
  handleDisconnect,
}: PropperOverlayProps) => {
  return (
    <Popper
      sx={{
        zIndex: 1,
      }}
      open={isOptionsOpen}
      role={undefined}
      anchorEl={currentAnchor}
      transition
      disablePortal
      placement={isMobile ? 'bottom-end' : 'bottom'}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setIsOptionsOpen(false)}>
              <MenuList id="split-button-menu" autoFocusItem>
                <MenuItem onClick={() => setVisible(true)}>
                  Change wallet
                </MenuItem>
                <MenuItem onClick={handleDisconnect}>
                  Disconnect wallet
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

const BaseButton = ({
  children,
  connected,
  onClick,
  colorClass = 'text-white',
  icon,
  isMobile,
}: PropsWithChildren<BaseButtonProps>) => {
  return (
    <button
      className={`${
        connected ? 'text-black bg-white' : colorClass
      } border rounded-3xl ${
        isMobile ? 'px-[8px] py-[4px] ml-3' : 'px-[12px] py-[10px]'
      } flex justify-center items-center w-full max-w-[400px]`}
      onClick={onClick}
    >
      <Image
        src={icon ?? `/${connected ? 'buttonIconBlack.svg' : 'buttonIcon.svg'}`}
        alt="lightning-icon"
        width={20}
        height={20}
      />
      <span className="pl-[8px] text-base font-medium">{children}</span>
      {connected && <KeyboardArrowDownIcon />}
    </button>
  )
}
