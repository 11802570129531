import React, { PropsWithChildren } from 'react'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { useRouter } from 'next/router'
import { GlobalInfoBox } from '../../providers/InfoBoxProvider/InfoBox'

export const MainContainer = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const hideElements = router.pathname == '/post-order/[orderId]'
  return (
    <div
      className={`bg-black px-[16px] lg:px-[75px] w-full pt-0 py-[24px] min-h-[100dvh] relative ${
        hideElements ? 'h-[1px]' : 'h-auto'
      }`}
    >
      <div className="max-w-[1980px] m-auto flex flex-col h-full">
        <Header />
        {children}
        {!hideElements && <Footer />}
        <GlobalInfoBox />
      </div>
    </div>
  )
}
